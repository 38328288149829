
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "hide-pagination-when-there-is-only-one-page",
  components: {
    CodeHighlighter
  },
  data() {
    return {
      value: false
    };
  },
  setup() {
    return {
      code6
    };
  }
});
